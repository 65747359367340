import React from 'react'
import {Component} from 'react'
import './Gallery.css'
import Illustration from './Illustration';

class Gallery extends Component{
    constructor(props){
        super(props);
        this.state = {
            ...props
        }
    }

    render(){
        const illustrations = this.state.illustrations.map(val =>(
            <Illustration {...val} />
        ))
        
        return (
            <div className='gallery-container'>
                {illustrations}
            </div>
        )
    }
}

export default Gallery;