import React from 'react';
import './Illustration.css';

function Illustration(props){
    // address props
    const {url} = {...props};
    var shape = '';
    if(props.shape !== undefined) {shape = props.shape;}



    const classes = `${shape} card`;
    const styles = {backgroundImage: `url(${url})`}
    return(
        // build component
        <div className={classes} style={styles}>
        </div>
    )
}


export default Illustration;