import React from 'react';
import {Component} from 'react';
import './Project.css'
import Gallery from './Gallery';


class Project extends Component{
    constructor(props){
        super(props);
        this.state = {
            ...props
        }
        this.illustrations = props.data.map(val =>{
            return { "name": val.name, "url": val.url, "shape": val.shape };
        })
        // console.log(this.urls);
    }
    render(){

        return(
        <div className="project-container">
            <h2>Inktober Gallery</h2>
            <Gallery illustrations={this.illustrations} />
            <nav>test</nav>
          </div>
        )
    }
}

export default Project;