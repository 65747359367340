import React from 'react';
import './App.css';
import Project from './Project';
import * as Inktober2019 from './data/projects/inktober_2019.json'
console.log(Inktober2019);
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Project name={Inktober2019.name} data={Inktober2019.data} />
      </header>
    </div>
  );
}

export default App;
